<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="'auto'"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  [(fullScreen)]="fullScreen"
>

  <dx-popup
    [(visible)]="displayPopupDuplicate"
    [showCloseButton]="true"
    [width]="800"
    [height]="'auto'"
    title="Dupliquer une journée sur une ou plusieurs autres journée(s)"
  >
    <div class="row">
      <div class="col-sm-6">
        <dx-select-box #inputTournees width="100%"
                       [dataSource]="daysWeek" [(value)]="dayToDuplicateSelected" placeholder="Sélectionner une journée à dupliquer *..." [searchEnabled]="true"
                       [showSelectionControls]="true" (onValueChanged)="onChangeDayToDuplicate($event)">
        </dx-select-box>
      </div>
      <div class="col-sm-6">
        <dx-tag-box [(value)]="daysTargetForDuplication"
                    [dataSource]="daysWeekTarget" placeholder="Sélectionner une ou plusieurs journée(s) cible(s) *..." [searchEnabled]="true"
                    [showSelectionControls]="true">
        </dx-tag-box>
      </div>
    </div>
    <div class="row mg-t-15">
      <div class="col-sm-12 text-right">
        <yo-button
          buttonClass="cta-success"
          iconClass="fa fa-copy"
          label="Dupliquer"
          (onClick)="duplicateDay()"
          >
        </yo-button>
      </div>
    </div>
  </dx-popup>

  <dx-popup
    [(visible)]="displayPopupAddTournee"
    [showCloseButton]="true"
    [width]="500"
    [height]="'auto'"
    title="Ajouter une tournée"
  >
    <div class="row">
      <div class="col-sm-6">
        <div class="genlabel required">Libellé <span class="danger-color">*</span> </div>
        <dx-text-box [(value)]="labelTourneeToCreate"> </dx-text-box>
      </div>
      <div class="col-sm-6">
        <div class="genlabel required">Poids max sous essieux </div>
        <dx-number-box [(value)]="poidsMaxTourneeToCreate"> </dx-number-box>
      </div>
    </div>
    <div class="row mg-t-15">
      <div class="col-sm-12 text-right">
        <yo-button
          buttonClass="cta-success"
          iconClass="fas fa-save"
          label="Enregistrer"
          (onClick)="addTournee()">
        </yo-button>
      </div>
    </div>
  </dx-popup>

  <dx-popup
    [(visible)]="displayPopupPeriods"
    [showCloseButton]="true"
    [width]="500"
    [height]="'auto'"
    title="Ajouter une période"
  >
    <div class="row">
      <div class="col-sm-6">
        <div class="genlabel required">Date de début <span class="danger-color">*</span> </div>
        <dx-date-box #start [(value)]="dateStart"
                     (onValueChanged)="startDateChanged($event)" type="date"> </dx-date-box>
      </div>
      <div class="col-sm-6">
        <div class="genlabel required">Date de fin <span class="danger-color">*</span> </div>
        <dx-date-box #end [(value)]="dateEnd"
                     (onValueChanged)="endDateChanged($event)"
                     [min]="minDate" type="date"> </dx-date-box>
      </div>
    </div>
    <div class="row mg-t-15">
      <div class="col-sm-12 text-right">
        <yo-button
          buttonClass="cta-success"
          iconClass="fas fa-save"
          label="Enregistrer"
          (onClick)="addPeriod()">
        </yo-button>
      </div>
    </div>
  </dx-popup>

  <div id="content">
    <div class="yo-card" [ngClass]="displayFullScreenOrganisationTournee ? 'hidden' : ''">
      <div class="yo-card-header">
        Informations générales
      </div>
      <div class="yo-card-body">
        <ng-container *ngIf="organisationTournees">
          <div class="row">
            <div class="col-sm-6">
              <div class="row no-margin mg-b-10 ">
                <div class="col-sm-2 align-flex-center genlabel required">Libellé <span class="danger-color">*</span> </div>
                <div class="col-sm-10">
                  <div class="input-group-sm">
                    <dx-text-box [(ngModel)]="organisationTournees.label"></dx-text-box>
                  </div>
                </div>
              </div>
              <div class="row no-margin">
                <div class="col-sm-2 align-flex-center genlabel required">Site <span class="danger-color">*</span> </div>
                <div class="col-sm-10">
                  <dx-lookup
                    [dataSource]="findAllLocalSites()"
                    [value]="organisationTournees?.site?.id"
                    displayExpr="libelle"
                    valueExpr="id"
                    [searchEnabled]="true"
                    [placeholder]="sitePlaceholder"
                    (onSelectionChanged)="onChangeSite($event)"
                  >
                    <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
                  </dx-lookup>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row mg-b-10">
                <div class="col-sm-6">
                  <yo-button
                    class="mg-r-10"
                    iconClass="fa fa-plus"
                    (onClick)="openDialogPeriods()"
                    label="Ajouter une période"
                    tooltip="Ajouter une période">
                  </yo-button>
                </div>
                <div class="col-sm-6 text-right">
                  <yo-button
                    buttonClass="cta-delete"
                    iconClass="fas fa-trash"
                    tooltip="Supprimer les éléments sélectionnés"
                    [disabled]="selectedRowsPeriods.length === 0"
                    (onClick)="deletePeriodes()"
                  >
                  </yo-button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <dx-data-grid
                    [dataSource]="organisationTournees.periods"
                    [height]="110"
                    class="data-grid"
                    [hoverStateEnabled]="true"
                    [rowAlternationEnabled]="true"
                    [allowColumnResizing]="true"
                    [showRowLines]="true"
                    [showBorders]="true"
                    [(selectedRowKeys)]="selectedRowsPeriods"
                    noDataText="Aucune période"
                    columnResizingMode="widget">
                    <dxo-selection
                      selectAllMode="allPages"
                      showCheckBoxesMode="always"
                      mode="multiple"
                    ></dxo-selection>
                    <dxi-column caption="Date de début" dataType="date" dataField="start" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
                    <dxi-column caption="Date de fin" dataType="date" dataField="end" [allowFiltering]="false" [allowGrouping]="false"></dxi-column>
                  </dx-data-grid>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="yo-card" [ngClass]="displayFullScreenOrganisationTournee ? 'hidden' : ''">
      <div class="yo-card-header">
        Ajout d'une ligne pour l'organisation
      </div>
      <div class="yo-card-body" >
        <div class="row">
          <div class="col-sm-12 mg-b-4"><i>En ne sélectionnant aucune prestation, aucune famille de plats ou aucun plat, c'est comme si vous sélectionniez l'ensemble des prestations, des familles de plats ou des plats</i></div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <dx-select-box #inputTournees width="100%"
                           [dataSource]="tournees" [(value)]="tourneeSelected" placeholder="Sélectionner une tournée *..." displayExpr="libelle" [searchEnabled]="true"
                           [showSelectionControls]="true">
            </dx-select-box>
            <i class="fas fa-plus pointer absolute-cta" [class.forbidden-cta]="!idSelectedSite" [title]="!idSelectedSite ? 'Veuillez ajouter un site à votre organisation pour pouvoir créer une tournée' : 'Cliquez ici pour créer une tournée'" (click)="openDialogAddTournee()"></i>
          </div>
          <div class="col-sm-2">
            <dx-select-box #inputPointsLivraisonsClient [(value)]="plcSelected"
                           [dataSource]="pointsLivraisons" placeholder="Sélectionner un point de livraison client *..." displayExpr="libelle" [searchEnabled]="true"
                           [showSelectionControls]="true" (onValueChanged)="onChangePointLivraisonClient($event)">
            </dx-select-box>
          </div>
          <div class="col-sm-1">
            <dx-number-box #inputOrdre [(value)]="orderSelected" placeholder="Ordre *" >
            </dx-number-box>
          </div>
          <div class="col-sm-3">
            <dx-select-box #inputJoursLivraisons [(value)]="jourLivSelected"
                           [dataSource]="daysWeek" placeholder="Sélectionner un jour de livraison *..." [searchEnabled]="true"
                           [showSelectionControls]="true">
            </dx-select-box>
          </div>
          <div class="col-sm-3">
            <dx-tag-box #inputJoursConso [(value)]="jourConsoSelected"
                        [dataSource]="currentConsumptionDays" placeholder="Sélectionner un jour de consommation *..." [searchEnabled]="true"
                        [showSelectionControls]="true">
            </dx-tag-box>
          </div>
        </div>
        <div class="row mg-t-15">
          <div class="col-sm-3">
            <dx-tag-box #inputRepas
                        [(value)]="repasSelected"
                        [dataSource]="repas" placeholder="Sélectionner un ou plusieurs repas *..." displayExpr="libelle" [searchEnabled]="true"
                        [showSelectionControls]="true" (onValueChanged)="onChangeRepas($event)">
            </dx-tag-box>
          </div>
          <div class="col-sm-3">
            <dx-tag-box #inputPrestations applyValueMode="useButtons" [(value)]="prestationsSelected" [disabled]="repasSelected.length === 0"
                        [dataSource]="prestations" placeholder="Sélectionner une ou plusieurs prestation(s)..." displayExpr="libelle" [searchEnabled]="true"
                        [showSelectionControls]="true">
            </dx-tag-box>
          </div>
          <div class="col-sm-1">
            <label class="special-placeholder">Sélectionner une ou plusieurs famille(s) de plats : </label>
          </div>
          <div class="col-sm-2">
            <dx-drop-down-box
              id="dropFamilles"
              [(value)]="treeBoxValue"
              valueExpr="id"
              displayExpr="libelle"
              [dataSource]="allFamillesProduits"
              placeholder="Sélectionner une ou plusieurs famille(s) de plats..."
              [inputAttr]="{ 'aria-label': 'Sélectionner une ou plusieurs famille(s) de plats...' }"
              (onValueChanged)="onDropDownBoxValueChanged($event)"
              fieldTemplate="fieldTemplate"
              [showClearButton]="true"
            >
              <div *dxTemplate="let info of 'fieldTemplate'">
                <dx-text-box placeholder="Sélectionner une ou plusieurs famille(s) de plats..." [visible]="false"></dx-text-box>
                <dx-tag-box
                  [dataSource]="allFamillesProduits"
                  [(value)]="treeBoxValue"
                  valueExpr="id"
                  displayExpr="libelle"
                  placeholder="Sélectionner une ou plusieurs famille(s) de plats..."
                  [openOnFieldClick]="false"
                  width="100%"
                  (onValueChanged)="onTagBoxValueChanged($event)"
                >
                </dx-tag-box>
              </div>
              <div *dxTemplate="let data of 'content'">
                <dx-tree-view
                  #treeView
                  placeholder="Sélectionner une ou plusieurs famille(s) de plats..."
                  [dataSource]="allFamillesProduits"
                  dataStructure="plain"
                  keyExpr="id"
                  parentIdExpr="parent.id"
                  selectionMode="multiple"
                  showCheckBoxesMode="normal"
                  [selectNodesRecursive]="false"
                  displayExpr="libelle"
                  [selectByClick]="true"
                  (onContentReady)="onTreeViewReady($event)"
                  (onItemSelectionChanged)="onTreeViewSelectionChanged($event)"
                >
                </dx-tree-view>
              </div>
            </dx-drop-down-box>
          </div>
          <div class="col-sm-3">
            <dx-text-box #inputPlats [(value)]="codesPlats" placeholder="Saisir un ou plusieurs codes plat(s) séparés par une virgule...">
            </dx-text-box>
          </div>
        </div>
        <div class="row mg-t-15">
          <div class="col-sm-12 text-right">
            <yo-button iconClass="fas fa-plus" label="Ajouter la/les ligne(s)" [disabled]="!addRowsEnabled()" (onClick)="addRows()"></yo-button>
          </div>
        </div>
      </div>
    </div>

    <div class="yo-card">
      <div class="yo-card-header">
        <div class="row" id="title-vis">
          <div class="col-sm-3">Visualisation de l'organisation de tournée(s) par jour de livraison</div>
          <div class="col-sm-9 text-right">
            <yo-button
              [iconClass]="displayFullScreenOrganisationTournee ? 'fa fa-compress-alt' : 'fa fa-expand-alt'"
              (onClick)="switchFullScreenOrganisationTournee()"
              class="mg-r-5"
              [tooltip]="displayFullScreenOrganisationTournee ? 'Réduire l\'onglet' : 'Plein écran'">
            </yo-button>
            <yo-button
              iconClass="fa fa-copy"
              (onClick)="openDialogDuplicateDay()"
              class="mg-r-5"
              tooltip="Dupliquer une journée sur une ou plusieurs autres journée(s)">
            </yo-button>
            <yo-button
              buttonClass="cta-delete"
              iconClass="fas fa-trash"
              tooltip="Supprimer les éléments sélectionnés"
              (onClick)="deleteItems()"
              [disabled]="selectedRows.length === 0"
            >
            </yo-button>
          </div>
        </div>
      </div>
      <div class="yo-card-body">
        <ng-container *ngIf="organisationTournees && organisationTournees.informationsByDeliveryDay">
          <dx-tab-panel height="100%">

            <dxi-item [title]="day" icon="event" *ngFor="let day of daysWeek" template="test">
              <dx-data-grid
                [dataSource]="organisationTournees.informationsByDeliveryDay[day.toUpperCase()]"
                height="100%"
                width="100%"
                [hoverStateEnabled]="true"
                [rowAlternationEnabled]="true"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [showRowLines]="true"
                [showBorders]="true"
                [(selectedRowKeys)]="selectedRows[day.toUpperCase()]"
                (onExporting)="onExporting($event)"

                noDataText="Aucune période"
                columnResizingMode="widget">
                <dxo-selection [selectAllMode]="allMode" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>
                <dxi-column caption="Tournée" dataField="tournee.libelle" [allowFiltering]="false" [allowSearch]="true" [groupIndex]="0"></dxi-column>
                <dxi-column caption="Ordre" dataField="order" [allowFiltering]="false" [allowSearch]="true" [groupIndex]="1"></dxi-column>
                <dxi-column caption="Point de livraison client" dataField="pointLivraison.libelle" [allowFiltering]="false" [allowSearch]="true"></dxi-column>
                <dxi-column caption="Jour de consommation" dataField="consumptionDay" [allowFiltering]="false" [allowSearch]="true"></dxi-column>
                <dxi-column caption="Repas" dataField="repas.libelle" [allowFiltering]="false" [allowSearch]="true"></dxi-column>
                <dxi-column caption="Prestation" dataField="prestation.libelle" cellTemplate="prestationTemplate" [allowFiltering]="false" [allowSearch]="true"></dxi-column>
                <dxi-column caption="Famille de plat" dataField="famillesPlats" cellTemplate="famillesPlatsTemplate" [allowFiltering]="false" [allowSearch]="true"></dxi-column>
                <dxi-column caption="Plat" dataField="plats" cellTemplate="platsTemplate" [allowFiltering]="false" [allowSearch]="true"></dxi-column>

                <div *dxTemplate="let cell of 'prestationTemplate' ">
                  {{ cell.row.data.prestation ? cell.row.data.prestation.libelle : 'Toutes' }}
                </div>
                <div *dxTemplate="let cell of 'famillesPlatsTemplate' ">
                  {{ displayFamillesPlats(cell.row.data) }}
                </div>
                <div *dxTemplate="let cell of 'platsTemplate' ">
                  {{ displayPlats(cell.row.data) }}
                </div>

                <!--  EXCEL EXPORT-->
                <dxo-export [enabled]="true"></dxo-export>

                <!--  RECHERCHE FRONT-->
                <dxo-search-panel [visible]="true"></dxo-search-panel>

                <!--  SORTING-->
                <dxo-sorting mode="multiple"></dxo-sorting>

                <!--  GROUPING-->
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-grouping [autoExpandAll]="true"></dxo-grouping>

                <dxo-pager
                  [showPageSizeSelector]="true"
                  [showNavigationButtons]="false"
                  [allowedPageSizes]="[20, 50, 100, 200]"
                  [visible]="true">
                </dxo-pager>
              </dx-data-grid>
            </dxi-item>

          </dx-tab-panel>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 custom-button-container-right">
        <yo-button iconClass="fas fa-list-check" label="Vérifier la cohérence" (onClick)="checkRows()"></yo-button>
        <yo-button buttonClass="cta-success" iconClass="fas fa-save" label="Enregistrer" (onClick)="save()"></yo-button>
      </div>
    </div>
  </div>



  <dx-popup
    [(fullScreen)]="fullScreenWarningsErrors"
    [(visible)]="displayWarnsAndErrors"
    [showCloseButton]="true"
    [width]="'70%'"
    [height]="'70%'"
    title="Rapport"
  >
    <dxi-toolbar-item
      widget="dxButton"
      location="after"
      [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreenWarningsErrors
        }">
    </dxi-toolbar-item>

    <dx-tab-panel height="100%">
      <dxi-item title="Avertissements" icon="info" *ngIf="displayWarningsTab()">
        <yo-tabs-warnings-org-tournee [warnings]="warnings"></yo-tabs-warnings-org-tournee>
      </dxi-item>
      <dxi-item title="Erreurs" icon="warning" *ngIf="displayErrorsTab()">
        <yo-tabs-errors-org-tournee [errors]="errors"></yo-tabs-errors-org-tournee>
      </dxi-item>
    </dx-tab-panel>
  </dx-popup>

</dx-popup>
