import {Component, OnInit} from "@angular/core";
import {UtilsService} from "../../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {FormControl, FormGroup} from "@angular/forms";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";
import {DeclinaisonDTO} from "../../../core/dtos/declinaison-dto";
import {TypeProduitDTO} from "../../../core/dtos/type-produit-dto";
import {DeclinaisonsService} from "../../../core/services/entities/declinaisons.service";
import {GraphQLService} from "../../../core/services/technique/graphql.service";

@Component({
  selector: 'yo-declinaison-edition-dialog',
  templateUrl: './declinaison-edition-dialog.component.html',
  styleUrls: ['./declinaison-edition-dialog.component.scss']
})
export class DeclinaisonEditionDialogComponent implements OnInit {

  declinaison: DeclinaisonDTO;

  dialogTitle: string;

  subOpenDialog: Subscription;

  subGraphQLDeclinaisons: Subscription;

  displayDialog: boolean = false;

  sitePlaceholder: string;

  idSelectedSite: number;

  idSelectedTypeProduit: number;

  form: FormGroup;

  typesProduit: TypeProduitDTO[] = [];

  constructor(private utilsSvc: UtilsService,
              private declinaisonSvc: DeclinaisonsService,
              private i8nSvc: InternationalizationService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              private graphqlSvc: GraphQLService) {
  }

  ngOnInit() {
    this.fetchData();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
    this.utilsSvc.unsubscribe(this.subGraphQLDeclinaisons);
  }

  fetchData = (): void => {
    this.subGraphQLDeclinaisons = this.graphqlSvc.sendQuery(`
      {
          allTypesDeProduit(filters: {
                actif: true
              }) {
              id,
              libelle
          }
      }
    `)
      .subscribe((res) => {
        this.typesProduit = res.allTypesDeProduit;
      });
    this.initForm();
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.declinaisonSvc.openDialogEdition$
      .subscribe((d: DeclinaisonDTO) => {
        this.displayDialog = true;
        if (!d) {
          this.declinaison = new DeclinaisonDTO();
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_CREATION_DECLINAISON", null);
        } else {
          this.declinaison = d;
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_MODIFICATION_DECLINAISON", null);
        }
        this.initForm();
      });
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.declinaison?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.declinaison?.site?.id;
    this.idSelectedTypeProduit = this.declinaison?.typeProduit?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.declinaison?.libelle),
      code: new FormControl(this.declinaison?.code),
      actif: new FormControl(this.declinaison?.actif != null ? this.declinaison?.actif : true)
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  onChangeTypeProduit = ($event: any): void => {
    this.idSelectedTypeProduit = $event.selectedItem?.id;
  }


  canModify = () => {
    return this.declinaisonSvc.canModify(this.declinaison);
  }

  save = (): void => {
    if (this.form.valid) {
      let item: DeclinaisonDTO = {...this.declinaison };
      item.site = {id: this.idSelectedSite } as SiteDTO;
      item.libelle = this.form.controls['libelle'].value;
      item.code = this.form.controls['code'].value;
      item.typeProduit = { id: this.idSelectedTypeProduit } as TypeProduitDTO;
      item.actif = this.form.controls['actif'].value;
      this.declinaisonSvc.save(item)
        .subscribe(response => {
          this.declinaisonSvc.announceDeclinaisonSaved();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de la déclinaison réalisé avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

}
