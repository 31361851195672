// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10472(a591afd8cb)-C29/07/2024-17:00:08-B29/07/2024-17:05:26' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10472(a591afd8cb)-C29/07/2024-17:00:08-B29/07/2024-17:05:26",
  branch: "master",
  latestTag: "6.1",
  revCount: "10472",
  shortHash: "a591afd8cb",
  longHash: "a591afd8cba64d85aa358b7fbf333113cca43b29",
  dateCommit: "29/07/2024-17:00:08",
  dateBuild: "29/07/2024-17:05:26",
  buildType: "Ansible",
  } ;
